.geosuggest {
  position: relative;
}

.geosuggest__input-wrapper {
  height: 100%;
}

.geosuggest__input {
  width: 100%;
  height: 3rem;
  border-radius: 0.125rem;
  background-color: var(--color-input-bg);
  color: var(--color-body-text);
  padding: 0 0.625rem;
  border: 0.0625rem solid var(--color-line);

  &:focus,
  &:active {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
}

.geosuggest__suggests {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 25em;
  padding: 0;
  margin-top: -1px;
  background: var(--color-body-bg);
  border: 1px solid var(--light-line);
  border-top-width: 0;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;
  z-index: 5;
  transition: max-height 0.2s, border 0.2s;
}
.geosuggest__suggests--hidden {
  max-height: 0;
  overflow: hidden;
  border-width: 0;
}

.geosuggest__item {
  padding: 0.375rem 0.625rem;
  cursor: pointer;

  &:hover,
  &:focus {
    background: var(--lighter-line);
  }
}

.geosuggest__item--active {
  background: var(--blue-on-light);
  color: #fff;
}

.geosuggest__item__matched-text {
  font-weight: bold;
}
