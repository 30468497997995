.check-list {
  li {
    margin-bottom: 0.5rem;

    svg {
      @include size(1.5rem);
      color: var(--color-success);
      margin-right: 1rem;
    }
  }
}

.document-link {
  @include radius();
  display: flex;
  justify-content: space-between;
  height: 100%;
  margin-bottom: 1.5rem;
  padding: 1.5rem;
  border: 1px solid var(--light-line);
  color: var(--black);

  &:hover {
    color: var(--black);
    text-decoration: none;

    .document-link__icon {
      transform: translateX(0.25rem);
    }
  }

  & > div {
    display: flex;

    &:first-child {
      flex-direction: column;
    }

    &:last-child {
      align-items: center;
      justify-content: center;
    }
  }

  &.is-verified {
    .document-link__icon {
      color: var(--green-on-dark);
    }
    &:hover {
      .document-link__icon {
        transform: none;
      }
    }
  }
}

.document-link__name {
  font-size: rem(18px);
}

.document-link__status {
  opacity: 0.6;
  font-family: var(--font-body);
  font-size: rem(14px);
}

.document-link__points {
  margin-right: 2rem;
}

.document-link__icon {
  @include size(1.5rem);
  transition: transform 0.2s ease-in-out;
}

.identity-check {
  .is-disabled {
    @include disabled;
  }
}
