@import '~@fontsource/roboto/scss/mixins';
@include fontFaceCustom(
  $weight: 300,
  $display: swap,
  $woff2Path: '~@fontsource/roboto/files/roboto-latin-300-normal.woff2',
  $unicodeRange: true
);
@include fontFaceCustom(
  $weight: 400,
  $display: swap,
  $woff2Path: '~@fontsource/roboto/files/roboto-latin-400-normal.woff2',
  $unicodeRange: true
);
@include fontFaceCustom(
  $weight: 500,
  $display: swap,
  $woff2Path: '~@fontsource/roboto/files/roboto-latin-500-normal.woff2',
  $unicodeRange: true
);

@import '~@fontsource/damion/scss/mixins';
@include fontFaceCustom(
  $weight: 400,
  $display: swap,
  $woff2Path: '~@fontsource/damion/files/damion-latin-400-normal.woff2',
  $unicodeRange: true
);

@font-face {
  font-family: 'Spock Ess Alt 1';
  font-weight: 400;
  src: url(../../assets/fonts/spock_ess_alt_1_regular.woff2) format('woff2'),
    url(../../assets/fonts/spock_ess_alt_1_regular.woff) format('woff');
  font-display: swap;
}
@font-face {
  font-family: 'Spock Ess Alt 1';
  font-weight: 700;
  src: url(../../assets/fonts/spock_ess_alt_1_bold.woff2) format('woff2'),
    url(../../assets/fonts/spock_ess_alt_1_bold.woff) format('woff');
  font-display: swap;
}

body {
  font-family: var(--font-body);
}

$body-size: rem(16px);
$sm-size: rem(14px);

.title {
  font-family: var(--font-display);
  font-size: rem(26px);
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: -0.4px;
  // color: var(--color-header-text);
  color: var(--color-page-header);

  @media (min-width: $bp-sm) {
    font-size: rem(36px);
  }
  &.contract-title {
    @media (min-width: $bp-sm) {
      text-align: center;
    }
  }
  &.contract-title-view-all {
    @media (min-width: $bp-sm) {
      text-align: left;
    }
  }
}

h1,
.h1 {
  font-family: var(--font-display);
  font-size: rem(22px);
  line-height: 1.6;
  font-weight: 400;

  @media (min-width: $bp-sm) {
    font-size: rem(30px);
    line-height: 1.3;
  }
}

.h1-alt {
  font-family: var(--font-body);
  font-size: rem(18px);
  line-height: 1.5;
  font-weight: 300;
  color: var(--color-page-header);

  @media (min-width: $bp-sm) {
    font-size: rem(22px);
  }
}

h2,
.h2 {
  font-family: var(--font-display);
  font-size: rem(18px);
  line-height: 1.6;
  font-weight: 500;

  @media (min-width: $bp-sm) {
    font-size: rem(22px);
    font-weight: 500;
  }
}

h3,
.h3 {
  font-family: var(--font-body);
  font-size: rem(16px);
  line-height: 1.6;
  font-weight: 500;

  @media (min-width: $bp-sm) {
    // font-family: var(--font-display);
    font-size: rem(20px);
    font-weight: 300;
  }
}

h4,
.h4 {
  font-family: var(--font-body);
  font-size: rem(16px);
  line-height: 1.6;
  font-weight: 500;

  @media (min-width: $bp-sm) {
    // font-family: var(--font-display);
    font-size: rem(18px);
    font-weight: 500;
  }
}

p,
.p {
  font-family: var(--font-body);
  font-size: $body-size;
  line-height: 1.6;
  font-weight: 400;
}

.p--leading {
  font-family: var(--font-body);
  font-size: rem(18px);
  font-weight: 300;

  @media (min-width: $bp-sm) {
    font-size: rem(20px);
  }
}

.a--underline {
  text-decoration: underline;
}

small {
  font-size: $sm-size;
}

.definition-term {
  color: var(--secondary-black);
  font-size: rem(14px);
  font-weight: 500;
  &.bold-text {
    font-weight: bolder;
  }
}

ol.number-list {
  list-style-type: decimal;
  list-style-position: inside;

  li {
    list-style: inherit;
  }
}
