html {
  scroll-behavior: smooth;
}

.page {
  color: var(--color-body-text);
}

// New responsive breakpoint
.container-xxl {
  max-width: $bp-xxl;
}

.table th,
td {
  font-size: 15px;
}

// Only display focus outline when used on keyboard, working together with focus-visible package
.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.arrow-back {
  margin-bottom: 1rem;
  svg {
    @include link-alt;
  }
}

hr {
  border-color: var(--color-line-light);
}

a {
  color: var(--color-link);
}
