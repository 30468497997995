.table {
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 4px;

  @media (min-width: $bp-md) {
    border: 1px solid var(--color-line);
  }

  thead th {
    &:first-child {
      border-top-left-radius: 4px;
    }
    &:last-child {
      border-top-right-radius: 4px;
    }

    padding-top: 1rem;
    padding-bottom: 1rem;
    // border-bottom-width: 1px;
    color: var(--color-table-head, --color-body-text);
    font-weight: 700;
    border: none;
  }

  tr {
    background-color: var(--color-body-bg);
    color: var(--color-body-text);
    transition: background-color 0.5s;
  }

  tbody tr:hover {
    background-color: var(--color-hover-highlight);
  }

  th,
  td {
    border-color: var(--color-line);
    @media (min-width: $bp-lg) {
      padding-top: 1rem;
      padding-bottom: 1rem;
      word-wrap: break-word;
    }
  }

  .dark-mode & {
    th,
    td {
      color: var(--color-body-text);
    }

    thead th {
      border-color: var(--color-line);
    }
  }

  .col-alert-dot {
    width: 2rem;
    padding-left: 1rem;
  }

  .col-name {
    width: 150px;

    @media (min-width: $bp-xl) {
      width: 200px;
    }
    word-break: break-all;
    .col-name-email {
      font-style: italic;
      font-size: 0.8rem;
      font-weight: 200;
    }
  }
  .col-status {
    width: 80px;
  }
  .col-payrollId {
    width: 100px;
  }
  .col-role {
    width: 150px;
    word-break: break-all;
  }
  .col-branch {
    width: 150px;
  }
  .col-state {
    //min-width: 60px;
    width: 90px;
    // text-align: center;
  }

  .col-contract-type {
    //min-width: 60px;
    width: 110px;
    // text-align: center;
  }

  .col-date {
    min-width: 110px;
    // width: 120px;
  }

  .col-workOrder {
    //min-width: 110px;
    width: 90px;
    word-break: break-all;
  }

  .col-progress {
    width: 120px;
    padding-right: 2rem;
  }

  .col-shield {
    text-align: center;

    svg {
      &.is-text-success {
        color: var(--color-success-wcag) !important;
      }
      // color: var(--color-line);
      color: var(--color-error);
    }
  }
}

.react-bootstrap-table {
  .table-row {
    cursor: pointer;
  }
}

.table-header {
  border: 1px solid var(--color-line);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom: 0;
  // display: flex;
  // justify-content: space-between;

  .search-container {
    width: 200px;
    border-left: 1px solid var(--color-line);
    margin: 10px;
    transition: 0.2s;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);

    &.expanded {
      transition: 0.2s;
      transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
      width: 400px;
    }

    input {
      border: none;
      margin-left: 0.5rem;
      padding-left: 2rem;
      background-color: transparent;
      background-image: var(--search);
      background-repeat: no-repeat;
      background-position: 0.5rem center;

      height: 2rem;
    }
  }
}

//when loading a table, put border around so it looks like table loading
.table-placeholder {
  border: 1px solid var(--color-line);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 5rem 0;
}

.react-bootstrap-table .row-expansion-style {
  padding: 0;
}

.expanded-renderer {
  background-color: rgba(51, 51, 51, 0.05);
  padding: 1rem 4rem;
}
