// Overriding bootstrap default stylings

.modal-content {
  border-radius: 5px;
  background-color: var(--color-body-bg);

  .dark-mode & {
    background-color: var(--color-body-text);
  }
}

.modal-title {
  font-family: var(--font-display);
}

.modal-header {
  padding: 1.5rem 2rem;
  border-bottom: 0;

  .dark-mode & {
    color: var(--white);
  }

  .button {
    .dark-mode & {
      color: var(--secondary-white);
    }
  }

  .close {
    padding: 0.75rem; // Adjusted to be inline with revised h4 font-size
    color: var(--color-body-text);
    opacity: 1;
    font-weight: 300;
    font-size: 2rem;

    .dark-mode & {
      color: var(--secondary-white);
    }
  }
}

.modal-body {
  padding: 0 2rem 2rem;

  .dark-mode & {
    color: var(--white);
  }

  hr {
    .dark-mode & {
      border-color: var(--white-15);
    }
  }
  .reason-box.error {
    border-color: red;
  }
  .reason-error {
    color: red;
    font-size: 0.8rem;
  }
}

.modal-footer {
  padding-left: 2rem;
  padding-right: 2rem;
  justify-content: flex-start;

  .dark-mode & {
    border-color: var(--white-15);
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;

  .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
}

.modal-amplify__error {
  color: white;
  min-height: calc(100vh - 20px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: auto;
  width: 100%;
  max-width: none !important;
  margin-top: 0rem;
  margin-left: 0rem;
  @media (min-width: $bp-sm) {
    min-height: calc(100vh - 60px);
    justify-content: flex-start;
  }
  .modal-content {
    background-color: #33355f;
  }
  .close {
    color: white;
  }
}
