.job-details {
  @include radius();
  padding: 1rem;
  background-color: #f2f9ff;
  border: 1px solid var(--color-info);
  word-break: break-word; // Prevent email from overflow
  text-align: center;
}

$job-details-border: 1px solid #a4d3f8;

.job-details__section {
  border-top: $job-details-border;

  @media (min-width: $bp-sm) {
    > div:not(:first-of-type) {
      border-left: $job-details-border;
    }
  }
}

.job-details__note {
  color: var(--secondary-black);
}
