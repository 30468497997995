@import 'functions';

.appear-enter,
.appear-appear {
  opacity: 0;
  transform: translateX(10px);
}

.appear-enter-active,
.appear-appear-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 0.25s, transform 0.25s;
}

.appear-exit {
  opacity: 1;
}

.appear-exit-active {
  opacity: 0;
  transform: translateX(10px);
  transition: opacity 0.25s, transform 0.25s;
}
