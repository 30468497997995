.header {
  top: 0;
  width: 100%;
  background-color: var(--color-header);
  border-bottom: 1px solid var(--color-line);

  .header__logo {
    height: var(--logo-height, 32px);
    width: 200px;
    background-image: var(--logo);
    background-repeat: no-repeat;
    background-size: contain;
  }

  a {
    color: var(--color-header-text);
  }

  // Override
  &.navbar {
    padding-top: var(--header-vertical-padding, 16px);
    padding-bottom: var(--header-vertical-padding, 16px);
    border-bottom: 1px solid var(--color-line);
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 10%);
  }

  .navbar-brand {
    margin-right: 3rem;
    padding: 0;
  }

  // Hamburger menu button
  .header__toggle {
    border-color: transparent;

    .navbar-toggler-icon {
      background-image: var(--hamburger);
    }
  }

  // Main nav that displays across breakpoints
  .header__main-nav {
    margin: 2rem 0;

    @media (min-width: $bp-lg) {
      margin: 0 auto 0 1rem;
    }

    // Styled nav links
    .styled-link {
      position: relative;
      color: var(--color-header-text);
      font-family: var(--font-display);
      font-weight: 700;
      font-size: rem(22px);
      transition: opacity 0.25s ease-in-out;

      &:hover {
        color: var(--color-header-text);
      }

      &.is-active {
        &:before {
          content: '';
          width: 4px;
          height: 100%;
          border-left: 4px solid var(--color-btn-primary);
          position: absolute;
          left: -1rem;
          top: 0;

          @media (min-width: $bp-lg) {
            border: none;
          }
        }
      }

      & + & {
        margin-bottom: 1rem;
      }

      @media (min-width: $bp-lg) {
        margin-bottom: 0;
        padding-left: 1rem;
        padding-right: 1rem;
        font-size: rem(16px);
        opacity: 0.8;

        &:after {
          content: '';
          position: absolute;
          bottom: -1rem;
          left: 0;
          width: 50%;
          height: 4px;
          background-color: transparent;
          transform: translate(50%, 100%);
          transition: transform 0.25s ease-in-out,
            background-color 0.25s ease-in-out;
        }

        &:hover,
        &.is-active {
          color: var(--color-header-text);
          opacity: 1;
        }

        &.is-active:after {
          background-color: var(--color-btn-primary);
          transform: translate(50%, 0);
        }
      }
    }
  }

  // Replicated dropdown nav inside the main nav for sm & md breakpoints
  .dropdown-in-nav {
    // margin-top: 2rem;
    // border-top: 1px solid var(--color-line-light);

    @media (min-width: $bp-lg) {
      border-top: none;
      margin-top: 0;
      display: none;
    }

    .dropdown-in-nav--admin {
      margin-top: 2rem;
      padding-top: 2rem;
      border-top: 1px solid var(--color-line);
    }

    // &.dropdown-in-nav--admin {
    //   margin-top: 1rem;
    //   padding-top: 2rem;
    //   border-top: 1px solid var(--dark-line);
    // }

    .dropdown-in-nav__heading {
      color: var(--color-header-text);
      font-size: rem(22px);
      font-weight: 700;
    }

    .dropdown-in-nav__item {
      padding: 0.5rem 0;
      font-size: rem(18px);
      color: var(--color-header-text);
      font-weight: normal;

      &:hover {
        color: var(--color-header-text);
      }

      @media (min-width: $bp-lg) {
        font-size: 1rem;
      }
    }
  }

  // Dropdown nav on the right
  .header__dropdown {
    .dropdown-divider {
      border-top-color: var(--color-line);
      margin: 0;
    }

    .dropdown-toggle {
      font-weight: 300;
      font-size: rem(16px);

      &:hover {
        color: var(--color-header-text);
      }

      &:after {
        margin-left: 0.5rem;
        vertical-align: 0.2em;
      }

      @media (min-width: $bp-sm) {
        font-size: rem(16px);
        font-family: var(--font-display);
      }
    }

    .dropdown-menu {
      background: var(--color-body-bg);
      padding: 0;
      filter: drop-shadow(0px 2px 8px rgba(255, 255, 255, 0.15));
    }

    .dropdown-item {
      color: var(--color-body-text);
      padding-top: 0.7rem;
      padding-bottom: 0.7rem;
      &:hover {
        background: var(--color-line-light);
      }
    }
  }

  .header__dropdown--admin {
    display: none;

    @media (min-width: $bp-lg) {
      display: block;
    }
  }
}

.wrkr-powered {
  font-size: 0.8rem;
  color: var(--color-tertiary-text) !important;
  .logo {
    width: 50px;
    // background: url('../../images/logo_wrkr.svg');
    background-image: url('../../assets/images/logo_wrkr-grey.svg');
    background-size: 50px;
    background-repeat: no-repeat;
    display: inline-block;
    color: transparent;
  }
  &:hover {
    background-color: transparent !important;
  }
}
.worker-header__menu {
  color: var(--color-header-text);
}
