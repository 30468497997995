.alert {
  display: flex;
  flex-direction: column;
  padding: 1rem 1.25rem;
  background-color: rgba(255, 222, 107, 0.2);
  border: 1px solid var(--color-warning);

  @media (min-width: $bp-lg) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  p {
    margin-bottom: 0;
  }
}

.alert__left {
  flex: 1;
  margin-right: 1rem;
}

.alert__right {
  flex-shrink: 0;
  margin-top: 1.25rem;

  @media (min-width: $bp-lg) {
    margin-top: 0;
  }
}

.alert__buttons {
  margin-top: 1.25rem;
}

.alert__title {
  font-size: rem(18px);
  line-height: 1.5;
  font-weight: 700;
}
