.panel {
  @include radius();
  margin-bottom: 1rem;
  padding: 1rem;
  border: 1px solid var(--color-line);

  @media (min-width: $bp-md) {
    padding: 1.5rem;
  }
  &.disabled {
    cursor: not-allowed;
    opacity: 0.4;
    pointer-events: none;
  }
  &.error {
    border: 1px solid var(--red);
  }
}

@media (min-width: $bp-md) {
  .panel--sm {
    padding: 1rem;
  }
}

.panel-disabled {
  cursor: not-allowed;
  opacity: 0.4;
  pointer-events: none;
}
