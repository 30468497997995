.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100vh;
  background-color: #e9edee;
}

.login__logo {
  height: 40px;
  width: 150px;
  margin: 2.5rem;
  margin-left: auto;
  margin-right: auto;
  background-image: url(../../assets/images/logo_wrkr.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

amplify-authenticator {
  --container-height: auto;
  --border-radius: 4px;
  --amplify-primary-color: var(--black);
  --amplify-primary-tint: var(--link);
  --amplify-primary-contrast: var(--white);
  --amplify-font-family: var(--font-body);
}
