.document-download {
  padding: 1rem 1.25rem;

  .document-name {
    &:hover {
      text-decoration: underline;
    }
  }
}

.document-download-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: var(--color-line-light);
}

.document-upload-form {
  fieldset {
    border-bottom: none;
  }
}
