.button {
  @include radius();
  padding: 0.5rem 0.75rem;
  border: 1px solid transparent;
  font-size: 1rem;
  font-weight: 700;
  text-align: center;
  transition: all 0.15s ease-in-out;
  color: var(--color-body-text);
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;

    &:hover {
      //   background: none;
      //   color: var(--color-body-text);
      text-decoration: none;
    }
  }
}

a.button {
  text-decoration: none;
  &.disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5;
  }
}

// Primary background
.button--primary {
  color: var(--color-btn-primary-text);
  background-color: var(--color-btn-primary);
  border-color: var(--color-btn-primary);

  &:hover,
  &:focus {
    filter: brightness(95%);
    color: var(--color-btn-primary-text);
  }
}

// Transparent background
.button--secondary {
  color: var(--color-body-text);
  background-color: transparent;
  border-color: transparent;

  &:hover,
  &:focus {
    color: var(--color-body-text);
    text-decoration: underline;
  }
}

// Transparent background with outline
.button--outline {
  color: var(--color-body-text);
  background-color: transparent;
  border-color: var(--color-body-text);

  &:hover,
  &:focus {
    color: var(--color-body-bg);
    background-color: var(--color-body-text);
  }

  &:disabled {
    &:hover {
      background: none;
      color: inherit;
    }
  }
}

.button--dotted-outline {
  border: 2px var(--light-line) dashed;
  padding: 1rem 0;
  background-color: transparent;
  &:hover,
  &:focus {
    background-color: var(--color-line-light);
    color: var(--color-body-text);
  }
}

// Button styled like a link without any decorations
.button--link {
  color: var(--link);
  font-weight: 400;

  .dark-mode & {
    color: var(--blue-on-dark);
  }

  &:hover {
    text-decoration: underline;
  }
}

// Button with a decorative icon
.button--icon {
  display: inline-flex;
  align-items: center;

  .button__icon {
    width: 1.125rem;
    height: 1.125rem;
  }
}

// Button with an icon left to the text
.button--icon-left {
  padding-left: 0.75rem;

  .button__icon {
    margin-right: 0.625rem;
  }
}

// Button with an icon right to the text
.button--icon-right {
  padding-right: 0.75rem;

  .button__icon {
    margin-left: 0.625rem;
  }
}

// Larger size with according size adjustments
.button--lg {
  padding: 0.75rem 2rem;
  font-family: var(--font-display);
  font-weight: 500;
  font-size: rem(18px);
  line-height: 1.4;

  &.button--icon {
    .button__icon {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  &.button--icon-left {
    padding-left: 1rem;

    .button__icon {
      margin-right: 0.75rem;
    }
  }

  &.button--icon-right {
    padding-right: 1rem;

    .button__icon {
      margin-left: 0.75rem;
    }
  }
}

// .button--approve {
//   border: 1px solid var(--color-green);
//   color: var(--color-green);
//   &:hover {
//     background-color: var(--color-green);
//     color: var(--white);
//   }

//   &.button--icon {
//     color: var(--color-green);
//   }
// }

.add-buttons-sm-container {
  display: flex;
  position: fixed;
  bottom: 0rem;
  right: 0rem;
  z-index: 1;
  justify-content: space-around;
  align-items: center;
  width: 100vw;
  color: #000;
}

.bulk--btn {
  bottom: 5rem !important;
  color: #000;
}

.single--btn {
  color: #000;
}

// Add new flow/candidate button
.add-new-button--sm {
  @include size(3rem);
  display: flex;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  padding: 0;
  background: var(--color-body-bg);
  border: 1px solid var(--color-body-text);
  border-radius: 100%;
  z-index: 1;

  .button__icon {
    @include size(2rem);
    margin: auto;
  }

  @media (min-width: $bp-lg) {
    display: none;
  }
}

.add-new-button {
  display: none;
  @media (min-width: $bp-lg) {
    display: inline-flex;
  }
}

// Action button
.action-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  font-weight: 400;
  background: none;
  color: var(--color-body-text);

  &:not(:disabled):hover {
    .action-button__icon {
      background-color: var(--color-line-light);
    }
  }

  .action-button__icon {
    @include size(3.5rem);
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid var(--color-line);
    border-radius: 50%;

    svg {
      @include size(1.5rem);
    }
  }

  &.dropdown-toggle::after {
    display: none;
  }
}

.dropdown {
  position: relative;
  display: inline-block;
  z-index: 999;
}

.dropdown-content {
  display: none;
  position: absolute;
  border-color: var(--color-body-text);
}

.dropdown-link-container {
  border: 1px solid var(--color-body-text);
  .dark-mode & {
    span {
      color: black;
    }
    svg {
      color: black;
    }
  }
}

.dropdown-content a {
  // color: black;
  // // padding: 0.75rem 5rem;
  // text-decoration: none;
  // display: block;
  background-color: #fff;
  // border-radius: 0;
  // padding: 1rem 0.5rem;
}

.dropdown:hover .dropdown-content {
  display: block;
  background-color: #fff;
}

.bulk-upload-headers {
  th {
    padding: 1.25rem 2rem !important;
    background-color: rgba(51, 51, 51, 0.045);
    border: 0.5px solid var(--color-line) !important;
    white-space: nowrap;
  }

  // background-color: #E11F30 !important;
}

.bulk-upload-table {
  .expand-cell-header {
    width: 5rem !important;
  }
  .table-row {
    td {
      border: 0.5px solid var(--color-line) !important;
      white-space: nowrap;
    }
  }
}

.expand-cell {
  text-align: center !important;
}

.user-error-expand-renderer {
  background-color: #f5f5f5;
  padding: 1rem 2rem;
  p {
    margin: 0;
  }
  .react-bootstrap-table {
    width: calc(65vw);
  }

  .user-error-container {
    display: flex;
    // justify-content: center;
    align-items: center;
    margin-bottom: 1em;
  }

  .error-pill {
    padding: 0.25rem 0.9rem;
    background-color: #e11f30;
    color: #fff;
    font-size: 0.75rem;
    border-radius: 0.5rem;
  }

  .warning-pill {
    padding: 0.25rem 0.9rem;
    background-color: #fdcf4d;
    color: #000;
    font-size: 0.75rem;
    border-radius: 0.5rem;
  }
}

.expanded-container {
  border: 0.5px solid var(--color-line) !important;
  .react-bootstrap-table table {
    overflow-x: scroll;
    table-layout: auto !important;
  }
}

.bulk-upload-title {
  padding: 2rem 0 1.25rem 0;
  font-size: 1.75rem;
  // font-weight: 500;
}
