// Override existing CSS classes from react-toastify
.Toastify__toast-container {
  width: 100%;
  max-width: $max-width;
}

.Toastify__toast {
  padding: 1rem 1.5rem;
  font-family: inherit;
  background-color: var(--black);
  color: var(--white);
}

.Toastify__toast {
  @include radius();
}

.Toastify__dismiss {
  color: var(--white);
  text-transform: uppercase;
}
