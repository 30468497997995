[tabindex='-1']:focus:not(:focus-visible) {
  outline: 0 !important;
}

[hidden] {
  display: none !important;
}

button {
  background: transparent;
}

img {
  width: 100%;
  height: auto;
}

hr {
  border-top-color: var(--color-line);
}

ul,
ol {
  list-style: none;
  padding-inline-start: 0;

  ul,
  ol {
    margin-top: 0.5rem;
    padding-inline-start: 1rem;
  }
}

a[aria-disabled='true'] {
  color: currentColor;
  pointer-events: none;
}
