//--------- BOND THEME ----------//

// Light
.bond .admin {
  // Brand
  --color-page-bg: var(--blue);
  --color-spinner: var(--orangey-red);

  // Fonts
  --font-display: 'Spock Ess Alt 1', sans-serif;
  --font-body: 'Roboto', sans-serif;

  // Header
  --color-header: var(--blue);
  --color-header-text: var(--white);

  // Body
  --color-body-bg: var(--white);
  --color-body-text: var(--black);
  --color-body-text-inverse: var(--white);
  --color-secondary-body-bg: var(--grey-light);
  --color-secondary-body-text: var(--secondary-black);

  // Buttons
  --color-btn-primary: var(--orangey-red);

  // Lines
  --color-line: var(--light-line);
  --color-line-light: var(--lighter-line);

  // Statuses
  --color-default: var(--lighter-line);
  --color-error: var(--orangey-red);
  --color-warning: var(--yellow);
  --color-success: var(--green-on-dark);

  --color-input-bg: var(--off-white);
  --color-highlight: var(--teal);

  // Chevron colours
  --color-chevron-progress: var(--blue-on-light);
  --color-chevron-pending: var(--yellow);
  --color-chevron-completed: var(--green);
  --color-chevron-progress-text: var(--white);
  --color-chevron-pending-text: var(--black);
  --color-chevron-completed-text: var(--white);
}

.bond .home {
  background-image: url('./../../assets/images/bond-bg.svg');
  background-position: center 120px;
  background-repeat: no-repeat;
}

// Dark
.bond.dark-mode .admin {
  // Header
  --color-header: var(--blue);

  // Body
  --color-body-bg: var(--grey-dark);
  --color-body-text: var(--white);
  --color-body-text-inverse: var(--black);
  --color-secondary-body-text: var(--secondary-white);
  --color-secondary-body-bg: var(--navy-black);

  // Lines
  --color-line: var(--darker-line);
  --color-line-light: var(--dark-line);

  // Input
  --color-input-bg: var(--navy-black);

  // Chevron
  --color-chevron-progress: var(--blue-on-dark);
  --color-chevron-pending: var(--yellow);
  --color-chevron-completed: var(--green-on-dark);
  --color-chevron-progress-text: var(--white);
  --color-chevron-pending-text: var(--black);
  --color-chevron-completed-text: var(--white);
}
