.File-input {
  position: relative;
  max-width: 80rem;
  margin: 0 auto 2rem;
  padding: 2rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  color: var(--color-body-text);

  .dark-mode & {
    color: var(--white);
    outline-color: var(--white-15);
  }

  &:focus-within {
    outline: 2px solid var(--blue-md);
  }

  .File-input__dragndrop,
  .File-input__icon {
    display: none;
  }

  &.has-advanced-upload {
    outline: 2px dashed var(--color-line);
    transition: background-color 0.2s ease-in-out;

    &:focus-within {
      outline: 2px solid var(--blue-md);
    }

    .File-input__icon {
      display: flex;
    }

    .File-input__dragndrop {
      display: inline;
    }
  }

  &.is-dragover {
    outline-color: var(--color-highlight);
    // background-color: #fff;

    .File-input__icon {
      color: var(--color-highlight);
    }
  }
}

.File-input__icon {
  @include size(5rem);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem auto;
  background: var(--color-highlight);
  border-radius: 100%;
  text-align: center;
  color: var(--light-line);
  transition: color 0.2s ease-in-out;

  svg {
    @include size(2rem);
    color: var(--black);
  }

  polyline,
  line {
    animation: float 1.5s ease-in-out infinite;
  }
}

@keyframes float {
  0% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-5%);
  }

  100% {
    transform: translateY(0);
  }
}

.File-input__input {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;

  &:hover + .File-input__label {
    outline: 1px;
  }
}

.File-input__label {
  max-width: 80%;
  cursor: pointer;
  display: inline-block;
  margin-left: auto;
  margin-right: auto;

  .is-uploading & {
    display: none;
  }
}

.File-input__file-size {
  margin-left: 0.5rem;
  color: var(--color-secondary-body-text);
  .dark-mode & {
    color: var(--white-60);
  }
}

.File-input__uploading {
  display: none;
  margin-left: auto;
  margin-right: auto;

  .is-uploading & {
    display: flex;
    align-items: center;
  }
}

.File-input__download {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  color: var(--color-body-text);

  a,
  a:link,
  a:hover {
    color: inherit;
    text-decoration: inherit;
  }

  .dark-mode & {
    background-color: var(--white-05);
    border-color: var(--white-50);
    color: var(--white);
  }

  svg {
    .dark-mode & {
      color: var(--white);
    }
  }
}

.file-input__disabled {
  pointer-events: none;
  opacity: 0.4;
}
