// Breakpoints - don't tamper
$bp-sm: 576px;
$bp-md: 768px;
$bp-lg: 992px;
$bp-xl: 1200px;
$bp-xxl: 1400px;

// Misc
$max-width: 800px; // Max width for the content to cater to screen sizes bigger than mobile
$radius: 4px;
$grey-50: rgba(161, 160, 164, 0.5);
$blue: #2a69a4;
$variables: (
  'blue': '#1c3678',
  'blue-on-dark': '#67bcff',
  'blue-on-light': '#0078d4',
  'blue-alt': '#7cb1ff',
  'blue-md': '#008fff',
  'orangey-red': '#eb5600',
  'red': '#dc3545',
  'teal': '#a2ffe8',
  // 'green': '#1a9988',
  'green': '#3DB357',
  'green-on-dark': '#26cdb7',
  'green-transparent': 'rgba(126, 218, 146, 0.5)',
  'yellow': '#f5d735',
  'black': '#333',
  'secondary-black': '#6C6C6C',
  'navy-black': '#0e1524',
  'grey-dark': '#131c34',
  'grey-medium': '#999',
  'white': '#fff',
  'off-white': '#fcfcfc',
  'secondary-white': '#d0d2d6',
  'grey-light': '#e9edee',
  'grey-lighter': '#f2f4f5',
  'dark-line': 'rgba(255, 255, 255, 0.05)',
  'darker-line': 'rgba(255, 255, 255, 0.2)',
  'light-line': 'rgba(0, 0, 0, 0.15)',
  'lighter-line': 'rgba(0, 0, 0, 0.05)',
  'light-bg': '#f9f9f9',
  'dark-bg': '#232323',
  'link': '#0054d1',
  'teal-light': 'rgba(162, 255, 232, 0.1)',
  //b&ws
  'black-05': 'rgba(0, 0, 0, 0.05)',
  'black-50': 'rgba(0, 0, 0, 0.5)',
  'black-60': 'rgba(0, 0, 0, 0.6)',
  'white-05': 'rgba(255, 255, 255, 0.05)',
  'white-15': 'rgba(255, 255, 255, 0.15)',
  'white-50': 'rgba(255, 255, 255, 0.5)',
  'white-60': 'rgba(255, 255, 255, 0.6)',
  // Breakpoints - don't tamper
  'bp-sm': '576px',
  'bp-md': '768px',
  'bp-lg': '992px',
  'bp-xl': '1200px',
  'bp-xxl': '1400px',
  // Misc
  'max-width': '800px',
  // Max width for the content to cater to screen sizes bigger than mobile
  'radius': '4px',
  'timeline-active-blue': 'rgba(129, 225, 255, 0.1)',
);

@each $name, $value in $variables {
  $name: #{$value};
}

:root {
  @each $name, $value in $variables {
    --#{$name}: #{$value};
  }
}
/* Inserting this collapsed row between two flex items will make
 * the flex item that comes after it break to a new row */
.break {
  flex-basis: 100%;
  height: 0;
}
