.task {
  @include radius();
  display: flex;
  justify-content: space-between;
  height: 100%;
  padding: 1rem;
  background: var(--grey-light);
  border-left: 4px solid #d4ded4;
  color: var(--black);
  overflow: hidden;
  transition: 0.2s ease-in-out;

  @media (min-width: $bp-sm) {
    min-height: 150px;
  }

  &:hover {
    color: var(--black);
    text-decoration: none;
    border-left-width: 6px;
    background-color: var(--grey-lighter);
  }

  &.is-completed {
    border-left-color: var(--color-success);

    .task__action {
      color: var(--color-success);
    }
  }

  &.is-pending,
  &.is-rejected {
    border-left-color: var(--color-warning);
  }

  &.is-archived {
    border-left-color: var(--color-error);
  }

  &.is-not-ready {
    opacity: 0.4;
    pointer-events: none;

    .flow-card--locked & {
      opacity: 1;
      background-color: var(--color-locked-line);
      color: var(--color-locked-text);
    }

    .task__info {
      svg {
        opacity: 0.5;
      }
    }
  }
}

.task__info {
  display: flex;
  align-items: center;

  @media (min-width: $bp-sm) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.task__name {
  margin-top: 0.25rem;
  line-height: 1.3;
  max-width: 10.5rem;
}

.task__icon {
  width: 2rem;
  height: 2rem;
  margin-right: 0.8rem;

  @media (min-width: $bp-sm) {
    margin-bottom: 1.2rem;
  }
}

.task__action {
  margin-left: 0.5rem;
  align-self: center;

  @media (min-width: $bp-sm) {
    align-self: flex-end;
  }

  svg {
    @include size(1.5rem);
  }
}

.task__arrow {
  transition: transform 0.2s ease-in-out;
}

.task__status {
  opacity: 0.7;
  font-size: rem(14px);
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.1rem;
  font-weight: bold;
}

// Specifically targeting locked icon
.flow-card--locked .task__action svg {
  width: 1.2rem;
  height: 1.2rem;
  opacity: 0.5;
}
