.home {
  min-height: calc(100vh - 74px);
  overflow: hidden;

  .home__header {
    color: var(--color-home-header, var(--white));
    @media (min-width: $bp-lg) {
      margin-top: 3rem;
    }
  }

  section {
    max-width: $max-width;
    margin: 2rem 1rem 2rem;
    h3 {
      padding-top: 1.5rem;
      margin-bottom: -0.6rem;
      font-weight: bold;
    }
    @media (min-width: $bp-md) {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .h1-alt {
    max-width: 100%;
    margin: 0rem auto;
    font-weight: bold;
  }
  .outerBox {
    width: 95%;
    margin: auto;
    border: 1px solid var(--color-line);
    background-color: var(--white);
    margin-bottom: 1.5rem;
    padding: 0;
    border-radius: 0.5rem;
    box-shadow: -1px 1px 2px 0px #0000001a;
    @media (min-width: $bp-md) {
      width: $max-width;
      margin-left: auto;
      margin-right: auto;
      padding: 0 1.5rem;
    }
  }
}

.task-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: -0.5rem 0 0.5rem -0.5rem;

  @media (min-width: $bp-sm) {
    margin: -1rem 0 1rem -1rem;
  }

  > div {
    flex: 0 0 100%;
    padding: 0.5rem 0 0 0.5rem;

    @media (min-width: $bp-sm) {
      flex-basis: 50%;
      padding: 1rem 0 0 1rem;
    }
    @media (min-width: $bp-md) {
      flex-basis: 33.33%;
    }
  }
}
.profile-card-header {
  color: var(--white);
  font-size: 1.2rem;

  padding-top: 0.3rem;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.profile-card-body {
  background-repeat: no-repeat;
  background-size: 80px 80px;
  background-position-x: 1rem;
  background-position-y: 1rem;
  height: auto;
  margin: 0rem;
  @media (min-width: $bp-md) {
    min-height: 7rem;
  }
}
.profile-card-jobtitle {
  font-size: 1.3rem;
  color: var(--color-home-header);
  margin-top: 1rem;
  margin-left: 7rem;
}
.profile-card-company {
  font-size: 1rem;
  color: var(--color-home-header);
  margin-top: 0.5rem;
  margin-left: 7rem;
}
.profile-card-detail {
  font-size: 0.9rem;
  margin-left: 7rem;
  display: inline;
}
.profile-info {
  display: flex;
  flex-direction: column;
  margin-left: 7rem;
  font-size: 0.9rem;
  color: var(--color-home-header);
  margin-bottom: 1rem;
  svg {
    vertical-align: baseline;
    margin-right: 0.4rem;
  }
  @media (min-width: $bp-md) {
    flex-direction: row;
    flex-wrap: wrap;
    > div {
      padding-right: 0.7rem;
      overflow-wrap: break-word;
    }
  }
}
.no-inprogress-task {
  display: flex;
  flex-direction: column;
  align-items: center;
  svg.rocket {
    padding-top: 1rem;
    width: 8rem;
    height: 8rem;
  }
  h2 {
    text-align: center;
    padding-top: 4rem;
    padding-bottom: 2rem;
  }
}
