.flow-accordion {
  color: var(--black);

  // Force higher specificity over bootstrap default stylings
  .flow-card {
    &#{&} {
      @include radius();
    }

    // Force rounded corners to override bootstrap
    &:not(:last-of-type),
    &:not(:first-of-type) {
      @include radius();
      border-bottom: 1px solid var(--color-line);
    }
  }

  > .flow-card--locked {
    &#{&} {
      border: 1px solid var(--color-locked-line);
    }
  }
  .contract-status__topic {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    font-weight: 700;
  }
  .contract-status__heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .contract-status_viewall {
      margin-top: 1rem;
    }
  }
}

.flow-card {
  background: var(--white);

  & + & {
    margin-top: 1rem;
  }
  &.contract {
    @media (min-width: $bp-sm) {
      margin: auto;
      margin-bottom: 1rem;
    }
  }
  h6 {
    font-size: 0.8rem;
  }
}

.flow-card--locked {
  background-color: var(--color-locked-line);
  border: 1px solid var(--color-locked-line);
  opacity: 0.5;
  color: var(--color-locked-text);

  .flow-card__header {
    background-color: var(--color-locked-line);
  }

  .card-body {
    background-color: var(--color-locked-line);
  }

  .pill--locked {
    border: 1px solid var(--color-locked-text);
    color: var(--color-locked-text);
  }
}

.flow-card__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  // Override default .card-header stylings
  background-color: var(--white);
  border-top: 0;
  border-left: 0;
  border-right: 0;

  @media (min-width: $bp-sm) {
    padding-bottom: 0.75rem;
  }

  > svg {
    transform-origin: center;
    transition: transform 0.15s ease-in-out;
  }

  &.is-open {
    > svg {
      transform: rotate(-180deg);
    }
    div > svg {
      transform: rotate(-180deg);
    }
  }
  &.contract-card__header {
    border-bottom: 0;
    .chevron-contract > svg {
      position: absolute;
      top: 0.8rem;
      right: 1rem;
    }
  }
}

.flow-card__heading {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 1rem;
  &.flow {
    justify-content: space-between;
  }
  &.contract {
    flex-direction: row;
    img {
      width: 64px;
      height: 64px;
    }
  }

  @media (min-width: $bp-sm) {
    flex-grow: 1;
    flex-direction: row;
    align-items: center;
    &.contract {
      display: flex;
      flex-direction: row;
      justify-content: start;
    }
  }

  .flow-card__text {
    margin-bottom: 0.2rem;
    text-transform: capitalize;
    @media (min-width: $bp-sm) {
      margin-bottom: 0;
    }
    &.contract {
      display: flex;
      flex-flow: column wrap;
      text-align: left;
      padding-left: 1rem;
      .summary-text {
        display: flex;
        flex-direction: column;
        div > h2 {
          padding-left: 0.2rem;
          font-family: var(--font-body);
          font-size: rem(16px);
          line-height: 1.6;
          font-weight: 700;
        }
      }
      .contract-status {
        text-align: left;
        padding-top: 0.5rem;
        @media (min-width: $bp-sm) {
          padding-top: 3rem;
          margin-right: -1rem;
        }
      }
      @media (min-width: $bp-sm) {
        flex-grow: 1;
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }

  // Align the title and progress vertically center
  h2 {
    align-items: center;
    display: inline;
    font-size: rem(17px);

    @media (min-width: $bp-sm) {
      margin-bottom: 0;
      font-size: rem(20px);
    }
  }

  h1 {
    @media (min-width: $bp-sm) {
      margin-bottom: 0;
      font-size: rem(20px);
      align-items: center;
    }
  }

  h3 {
    @media (min-width: $bp-sm) {
      margin-bottom: 0;
    }
  }
}

.flow-card__progress {
  display: inline;
  font-family: var(--font-body);
  font-size: rem(14px);
  opacity: 0.6;
  display: inline-block;
  vertical-align: text-bottom;
  margin-left: 0.2rem;

  @media (min-width: $bp-sm) {
    vertical-align: text-bottom;
    padding-top: 2px;
  }
}
