//--------- DEFAULT LIGHT MODE ----------//

:root {
  // Brand
  --logo: url('./../../assets/images/bond-logo.svg');
  --color-page-bg: var(--blue);

  // Fonts
  --font-display: 'Spock Ess Alt 1', sans-serif;
  --font-body: 'Roboto', sans-serif;
  --font-handwriting: 'Damion', cursive;

  // Header
  --color-header: var(--blue);
  --color-header-text: var(--white);

  // Nav
  --color-sidenav-bg: #fafafa;

  // Body
  --color-body-bg: var(--white);
  --color-body-text: var(--black);
  --color-body-text-inverse: var(--white);
  --color-secondary-body-bg: var(--grey-light);
  --color-secondary-body-text: var(--secondary-black);
  --color-tertiary-text: var(--black-60);

  // Background
  --color-light-bg: var(--light-bg);

  // Body dark bg (worker portal)
  --color-locked-text: var(--white);
  --color-locked-line: var(--darker-line);

  // Buttons
  --color-btn-primary: var(--orangey-red);
  --color-btn-primary-text: var(--white);

  // Lines
  --color-line: var(--light-line);
  --color-line-light: var(--lighter-line);

  // Statuses
  --color-error: var(--red);
  --color-warning: var(--yellow);
  --color-success: var(--green-on-dark);
  --color-info: var(--blue-md);
  --color-success-wcag: var(--green);
  --color-success-transparent: var(--green-transparent);
  --color-archive: var(--grey-medium);

  // Input
  --color-input-bg: var(--off-white);
  --color-highlight: var(--teal);

  // Spinner
  --color-spinner: var(--orangey-red);

  // Chevron colours
  --color-chevron-progress: var(--blue-on-light);
  --color-chevron-pending: var(--yellow);
  --color-chevron-completed: var(--green);
  --color-chevron-progress-text: var(--white);
  --color-chevron-pending-text: var(--black);
  --color-chevron-completed-text: var(--white);

  // Misc
  --color-hover: rgba(0, 0, 0, 0.1);
  --color-hover-highlight: var(--teal-light);
  --color-link: var(--link);

  // Background images
  --hamburger: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgb%280, 0, 0%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --search: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'/%3E%3C/svg%3E");
  --cross: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.96888 6.96888C7.03854 6.89904 7.12131 6.84362 7.21242 6.80581C7.30354 6.768 7.40122 6.74854 7.49988 6.74854C7.59853 6.74854 7.69621 6.768 7.78733 6.80581C7.87844 6.84362 7.96121 6.89904 8.03088 6.96888L11.9999 10.9394L15.9689 6.96888C16.0386 6.89915 16.1214 6.84384 16.2125 6.8061C16.3036 6.76836 16.4013 6.74894 16.4999 6.74894C16.5985 6.74894 16.6961 6.76836 16.7873 6.8061C16.8784 6.84384 16.9611 6.89915 17.0309 6.96888C17.1006 7.03861 17.1559 7.1214 17.1937 7.21251C17.2314 7.30362 17.2508 7.40127 17.2508 7.49988C17.2508 7.5985 17.2314 7.69615 17.1937 7.78726C17.1559 7.87837 17.1006 7.96115 17.0309 8.03088L13.0604 11.9999L17.0309 15.9689C17.1006 16.0386 17.1559 16.1214 17.1937 16.2125C17.2314 16.3036 17.2508 16.4013 17.2508 16.4999C17.2508 16.5985 17.2314 16.6961 17.1937 16.7873C17.1559 16.8784 17.1006 16.9612 17.0309 17.0309C16.9611 17.1006 16.8784 17.1559 16.7873 17.1937C16.6961 17.2314 16.5985 17.2508 16.4999 17.2508C16.4013 17.2508 16.3036 17.2314 16.2125 17.1937C16.1214 17.1559 16.0386 17.1006 15.9689 17.0309L11.9999 13.0604L8.03088 17.0309C7.96114 17.1006 7.87836 17.1559 7.78725 17.1937C7.69614 17.2314 7.59849 17.2508 7.49988 17.2508C7.40126 17.2508 7.30361 17.2314 7.2125 17.1937C7.12139 17.1559 7.03861 17.1006 6.96888 17.0309C6.89914 16.9612 6.84383 16.8784 6.80609 16.7873C6.76835 16.6961 6.74893 16.5985 6.74893 16.4999C6.74893 16.4013 6.76835 16.3036 6.80609 16.2125C6.84383 16.1214 6.89914 16.0386 6.96888 15.9689L10.9394 11.9999L6.96888 8.03088C6.89903 7.96121 6.84362 7.87845 6.80581 7.78733C6.768 7.69622 6.74854 7.59853 6.74854 7.49988C6.74854 7.40123 6.768 7.30355 6.80581 7.21243C6.84362 7.12131 6.89903 7.03855 6.96888 6.96888Z' fill='grey'/%3E%3C/svg%3E");
}
