.personal-information {
  .profile-picture__label {
    display: inline-flex;
    align-self: center;
    cursor: pointer;
    padding-left: 0.75rem;
    padding-right: 0.75rem;

    &:focus-within {
      border: 2px solid var(--blue-md);
    }
  }

  .profile-picture__input {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
  }
}
