.size-1_5rem {
  height: 1.5rem;
  width: 1.5rem;
}

.size-2rem {
  height: 2rem;
  width: 2rem;
}

.size-1_25rem {
  height: 1.25rem;
  width: 1.25rem;
}
