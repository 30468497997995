.profile-section {
  margin: -1rem 0rem -2rem;
  background-color: var(--brand-gray);
  display: flex;
  word-wrap: break-word;
  word-break: break-word;
  align-items: center;
  font-size: 0.9rem;
  padding: 1rem 1rem 1rem;
  @media (min-width: $bp-md) {
    margin: -1rem 0rem -2rem;
    padding: 0rem 1rem 0rem;
  }
}

.right-profile-section {
  padding: 0.2rem 1rem;
  @media (min-width: $bp-md) {
    padding: 2rem 1rem;
  }
}

.menu-section {
  margin: 2rem auto -3rem;
  display: flex;
  flex-direction: column;
  svg {
    margin-right: 1rem;
    stroke: black;
  }
  hr {
    margin: 0 0;
  }
  .menu-button {
    display: block;
    width: 100%;
    padding: 0.7rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
  }
}

.profile-button {
  color: var(--color-body-text);
  background-color: transparent;
  border-color: var(--color-body-text);
  border-radius: 5px;
  border-width: 0.12rem;
  font-size: 0.8rem;
  padding: 0.25rem 1rem 0.25rem;
}

.profile-image {
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  @media (min-width: $bp-md) {
    width: 5rem;
    height: 5rem;
  }
}

.ahpra-image {
  width: 20rem;
}

.modal-header-small {
  padding: 1.5rem 2rem;
  border-bottom: 0;

  .dark-mode & {
    color: var(--white);
  }

  .button {
    .dark-mode & {
      color: var(--secondary-white);
    }
  }

  .close {
    // padding: 0.75rem; // Adjusted to be inline with revised h4 font-size
    margin-top: -1.4rem;
    margin-right: -1.4rem;
    color: var(--color-body-text);
    opacity: 1;
    font-weight: 300;
    font-size: 1.2rem;
    .dark-mode & {
      color: var(--secondary-white);
    }
  }
}

.modal-small {
  position: fixed;
  width: 70%;
  right: 0.1rem;
  margin-top: 3rem;
  @media (min-width: $bp-md) {
    width: 20rem;
    right: 0.5rem;
    margin-top: 4rem;
  }
}
