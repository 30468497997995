// what is this pagination code for?? - CL

.table {
  margin-bottom: 0;
}
.pagination-container {
  border: 1px solid var(--color-line);
  border-top: none;
  margin-bottom: 1rem;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
//------ table pagination styles -------//
.pagination-table__show-rows label {
  margin: 0;
}
.pagination-table {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0.8rem 1rem;
  font-size: 0.875rem;

  &__show-rows {
    display: flex;
    align-items: center;
  }

  div {
    // border: 1px solid green;
    // vertical-align: middle;
    margin-left: 1rem;
  }

  &__buttons {
    .btn-pagination {
      border: none;
      background: transparent;
      cursor: pointer;
      &:disabled {
        cursor: default;
      }
    }
  }

  .nav-pagination {
    display: inline;
  }
}
