$gutter: 20px;

.employee-profile__container {
  max-width: 1600px;
  margin: 0 auto;
  min-height: calc(100vh - 88px);
}

.employee-profile__container {
  display: flex;
  flex-direction: column;

  @media (min-width: $bp-md) {
    padding: 0;
  }
  @media (min-width: $bp-lg) {
    flex-direction: row;
  }

  .employee-profile__main {
    flex-grow: 1;
    padding: 1rem;
  }

  .employee-profile__card {
    background-color: var(--color-body-bg);
    color: var(--color-body-text);
    margin: 1.5rem 0;
    padding: 1rem;
    border-radius: 0.3rem;
    border: solid 1px #33333326;
    box-shadow: -1px 1px 2px 0px #0000001a;
  }

  .employee-profile__avatar {
    width: 7rem;
    background-color: var(--color-btn-primary);
    border-radius: 50%;
    overflow: hidden;
    font-size: 2.5rem;
    // font-weight: 700;
    color: var(--color-btn-primary-text);
    font-family: var(--font-display);
    @media (min-width: $bp-md) {
      @include size(5rem);
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: fill;
      @media (min-width: $bp-md) {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .employee-profile__avatar.sm {
    @include size(5rem);
  }

  .employee-profile__heading {
    color: var(--color-home-header);
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    margin: 0;
  }
  h1.employee-profile__heading {
    font-size: rem(18px);
    @media (min-width: $bp-md) {
      font-size: rem(22px);
    }
  }

  .employee-profile__link {
    display: flex;
    justify-content: space-between;
    font-family: var(--font-display);
    font-size: 1rem;
    font-weight: 400;
    color: var(--color-secondary-body-text);
    border-bottom: 1px solid var(--color-line);
    padding: 1rem;
    svg {
      font-size: 1.5rem;
    }
  }
  p.employee__profile__title {
    font-family: var(--font-body);
    font-size: 0.8rem;
    font-weight: bold;
  }
}

.compliance-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 5rem;
}

.compliance-column {
  padding: 0.8rem;
  width: min-content;
  height: min-content;
  position: relative;
  div {
    text-align: center;
  }
}

.compliance-circle {
  border-radius: 50%;
  width: 6rem;
  height: 6rem;
  background-color: var(--color-page-bg);
  img {
    width: 2rem;
    height: 2rem;
    position: absolute;
    top: 2.7rem;
    left: 2.8rem;
    // transform: translate(-50%,-50%);
  }
}
.compliance-checked {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
