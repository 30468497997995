input[type='search']::-webkit-search-cancel-button {
  /* Remove default */
  -webkit-appearance: none;
  /* Now your own custom styles */
  height: 24px;
  width: 24px;
  cursor: pointer;
  background-image: var(--cross);
  background-repeat: no-repeat;
}
