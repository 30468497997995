.info-panel {
  @include radius();
  padding: 1rem;
  border: 1px solid var(--light-line);
  border-left: 4px solid var(--light-line);
  word-break: break-word; // Prevent email from overflow
  text-align: left;

  .dark-mode & {
    background-color: transparent;
    svg {
      color: var(--secondary-white);
    }
  }

  p {
    margin-bottom: 0;

    & + * {
      margin-top: 0.5rem;
    }
  }

  a {
    color: var(--color-link);
  }
}

.info-panel__heading {
  margin-bottom: 1rem;
  font-size: 1.125rem;
}

.info-panel__icon {
  @include size(1.25rem);
  margin-right: 1rem;
  color: #333;
}

.info-panel--sm {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  .info-panel__heading {
    font-size: 1rem;
  }

  p {
    font-size: rem(14px);

    & + * {
      margin-top: 0.5rem;
    }
  }
}

.info-panel--success {
  background-color: #fff;
  border-color: var(--color-success);

  .info-panel__icon {
    color: var(--color-success);
  }
}

.info-panel--pending {
  background-color: #fff;
  border-color: var(--color-warning);

  .info-panel__icon {
    color: var(--color-body-text);
  }
}

.info-panel--error {
  background-color: #fff;
  border-color: var(--color-error);

  .info-panel__icon {
    color: var(--color-error);
  }
}

.info-panel--info {
  border-color: var(--color-info);
  background-color: #fff;

  // .info-panel__icon {
  //   color: var(--color-info);
  // }
}

.info-panel--info-alt {
  background-color: var(--grey-lighter);
  border: none;

  .info-panel__icon {
    color: var(--secondary-black);
  }
}
