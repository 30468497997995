.switch {
  display: inline-flex;
  align-items: center;
  margin-bottom: 0;
}

.switch__inner {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 26px;
}

.switch__input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch__slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #3a4254;
  border-radius: 50px;
  transition: 0.25s ease;

  &:before {
    position: absolute;
    content: '';
    height: 22px;
    width: 22px;
    left: 2px;
    bottom: 2px;
    background-color: var(--white);
    border-radius: 50%;
    transition: 0.25s ease;
  }
}
.switch__input:checked + .switch__slider {
  background-color: var(--color-success);
}

.switch__input:focus + .switch__slider {
  box-shadow: 0 0 1px var(--color-success);
}

.switch__input:checked + .switch__slider:before {
  transform: translateX(22px);
}
