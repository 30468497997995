.sunsuper-promo {
  margin-bottom: 2rem;
  background-image: url('./../../assets/images/banner_art.png');
  background-size: cover;

  .sunsuper-promo__img {
    position: relative;

    background-repeat: no-repeat;
    display: block;
    height: 240px;

    // &:after {
    //   position: absolute;
    //   content: '';
    //   width: 100%;
    //   height: 20%;
    //   left: 0;
    //   bottom: 0;
    //   background: #0051ff;
    //   transform: skewY(4deg);
    //   transform-origin: left;
    // }
  }

  .sunsuper-promo__content {
    position: relative;
    background-color: #0051ff;
    background-image: url('./../../assets/images/logo_art.svg');
    background-size: 100px auto;
    background-position: calc(100% - 1rem) calc(100% - 1rem);
    background-repeat: no-repeat;
    padding: 1rem;
    color: #fff;
    @media (min-width: $bp-md) {
      background-size: 140px auto;
      background-position: calc(100% - 1.5rem) calc(100% - 1.5rem);
    }
  }

  h2,
  .sunsuper-promo__link {
    color: #fff;
  }
}

.super-choice-option {
  @include radius();
  position: relative;
  display: block;
  margin-bottom: 1rem;
  padding: 1.25rem 1rem;
  border: 1px solid var(--light-line);
  background: #fff;
  overflow: hidden;

  &:focus-within {
    border: 2px solid var(--color-highlight);
  }

  &.is-selected {
    border: 2px solid var(--color-success);
  }

  > label {
    font-size: 1.125rem;
    font-weight: 500;

    span {
      font-size: 1rem;
    }
  }

  .super-choice-option__icon {
    position: absolute;
    top: 20px;
    right: 15px;
    width: 20px;
    height: 20px;
    color: var(--color-success);
  }
}

.super-choice-radio {
  opacity: 0;
  position: absolute;

  + label {
    width: 100%;
    cursor: pointer;

    span {
      font-weight: 400;
      color: var(--secondary-black);
    }
  }
}

.continue-button {
  margin-top: 10px;
  float: right;

  .continue-button__icon {
    width: auto;
    height: 1rem;
    margin-left: 10px;
  }
}
